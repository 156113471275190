/**
 * Entrypoint für rollup
 *
 * Dokumentation und Hilfe zu rollup unter rollupjs.org
 *
 * Hinweis: Bei der Verwendung von ES2015 Features wie
 * promises, symbols, collections, iterators, typed arrays etc.
 * muss `core-js` importiert werden!
 *
 * Dokumentation dazu unter https://github.com/zloirock/core-js
 *
 * Wenn man zum Beispiel nur Promises verwenden will muss folgender Code am Anfang dieser Datei stehen:
 *
 *          import 'core-js/fn/promise'
 *
 */
import 'core-js/fn/object/assign'
import domready from 'domready'
import baguetteBox from 'baguettebox.js'
import categoryInit from './modules/kacheln'
import initModal from './modules/modal'
import formSubmit from './modules/form-submit'
import initMaps from './modules/map'
import debug from './modules/debug'
import Swiper from 'swiper'
/**
 * Ab geht die Reise
 */
domready(() => {
  categoryInit()
  initModal()
  formSubmit()
  initMaps()
  // eslint-disable-next-line
  new Swiper('#mmeinungen', {
    effect: 'slide',
    slidesPerView: 1,
    autoplay: 5000,
    speed: 2000,
    loop: true,
    pagination: '.swiper-pagination',
    paginationClickable: true
  })
// eslint-disable-next-line
  new Swiper('.leistungen-slider', {
    pagination: '.leistungen-slider-pagination',
    slidesPerView: 2,
    slidesPerColumn: 2,
    paginationClickable: true,
    spaceBetween: 0,
    autoplay: 5000,
    speed: 2000,
    loop: true,
    breakpoints: {
      // when window width is <= 320px
      768: {
        slidesPerView: 1,
        slidesPerColumn: 1
      },
      1024: {
        slidesPerView: 1,
        slidesPerColumn: 2
      }
    }
  })

  // eslint-disable-next-line
  baguetteBox.run('.gallery')
// eslint-disable-next-line
  debug()
})
